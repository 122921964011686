import { Suspense, useState, useMemo, useEffect, useRef  } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  OrbitControls,
  PerspectiveCamera,
  Environment,
  useProgress,
  Html,
  GizmoHelper,
  GizmoViewport
} from '@react-three/drei';
import { Leva, useControls } from 'leva'
import * as THREE from "three";

import jflogo from '../assets/jflogo.png';

import Progress from '../components/Progress';
import Car from '../components/Car';
import Background from '../components/Background';
import WheelAndDots from '../components/WheelAndDots';
import { EnvBuilder } from '../components/EnvBuilder';
// import Effects from '../components/Effects';

const HDRI_LIST = {
  'canary_wharf': require('../assets/hdri/canary_wharf_2k.hdr'),
  'sunset_quarry': require('../assets/hdri/sunset_quarry_001_2K.hdr'),
  'jhbcentral': require('../assets/hdri/sunset_jhbcentral_2k.hdr'),
  'the_sky_is_on_fire': require('../assets/hdri/the_sky_is_on_fire_2k.hdr'),
  'castel_st_angelo_roof': require('../assets/hdri/castel_st_angelo_roof_2k.hdr'),
};

function ConfiguratorSanbox(props) {
  const {
    wheelFile,
    carFile,
    hdriFile,
    hdrJpgFile,
    bgFile,
    noWheel,
    noBrake,
    brakeFile
  } = props;
  const camRef = useRef();
  const orbitRef = useRef();

  const [selectedCarIndex, setSelectedCarIndex] = useState(0);
  const [selectedWheelIndex, setSelectedWheelIndex] = useState(0);

  const [hasDrumBrake, setHasDrumBrake] = useState(false);
  const [wheelSize, setWheelSize] = useState(new THREE.Vector3());
  const [dots, setDots] = useState([]);
  const [tyreDepth, setTyreDepth] = useState(0);
  const [tyreNodes, setTyreNodes] = useState([]);
  const [showDelayLoading, setShowDelayLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectHdri, setSelectHdri] = useState(HDRI_LIST['sunset_quarry']);

  const { progress, loaded } = useProgress();

  const { color, colorRearLight, rearLightIntensity, suspension } = useControls('Car', {
    color: "#410000",
    suspension: {
      value: 0,
      min: -0.08,
      max: 0,
      step: 0.01,
    },
  })

  const { scale: wheelScale } = useControls('Rims', {
    scale: { value: 1, min: 0.80, max: 1.2, step: 0.01 },
  })

  const { showGround, groundHeight, groundRadius, groundScale } = useControls('Ground HDRI', {
    showGround: false,
    groundHeight: {
      value: 15,
      min: 0,
      max: 100,
      step: 0.01,
    },
    groundRadius: {
      value: 60,
      min: 0,
      max: 100,
      step: 0.01,
    },
    groundScale: {
      value: 1000,
      min: 0,
      max: 1000,
      step: 0.01,
    },
  })

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  function Loader() {
    return <Progress strokeWidth={8} percentage={progress} />;
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setShowDelayLoading(true)
      setShowDelayLoadingWithDelay({
        status: false,
        delay: 5000
      });
    }
  }, [progress, loaded])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  function setDotsFromCar(event) {
    setDots(event);
  }
  function setTyreDepthFromCar(event) {
    setTyreDepth(event);
  }
  function setTyreNodesFromCar(event) {
    setTyreNodes(event);
  }
  function setWheelSizeAfterScaling(event) {
    setWheelSize(event)
  }
  function setHasDrumBrakeFromCar(event) {
    setHasDrumBrake(event)
  }

  function setShowDelayLoadingWithDelay({ status, delay }) {
    if (!delay) {
      setShowDelayLoading(status)
      return;
    }
    setTimeout(() => {
      setShowDelayLoading(status)
    }, delay)
  }

  return (
    (wheelFile && carFile) || noWheel
      ? <div className="Configurator">
      <div className={'loading-overlay' + (showDelayLoading ? ' show' : '')}>
        <img src={jflogo} alt="jflogo" />
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
          }}
          shadows={THREE.VSMShadowMap}
          id="three-canvas"
        >
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 29.5}
            makeDefault
            position={
              isMobileAndTabletControlVertical
                ? [-9.708949167153316, 0.8967611230370927, 6.995150145482383]
                : [-7.043429265526343, 0.693896662497174, 6.010551626400343]
            }
          />
          <group position={[0, -0.4, 0]}>
            <color attach="background" args={['#f0f0f0']} />
            {
              noWheel
                ? <></>
                : <WheelAndDots
                  selectedCarIndex={selectedCarIndex}
                  selectedWheelIndex={selectedWheelIndex}
                  wheelFile={wheelFile}
                  dots={dots}
                  tyreDepth={tyreDepth}
                  tyreNodes={tyreNodes}
                  wheelScale={wheelScale}
                  hasDrumBrake={hasDrumBrake}
                  setWheelSize={setWheelSizeAfterScaling}
                  noBrake={noBrake}
                  brakeFile={brakeFile}
                />
            }
            <Car
              key={carFile}
              carId={carFile}
              modelFile={carFile}
              suspension={suspension}
              color={color}
              colorRearLight={colorRearLight}
              rearLightIntensity={rearLightIntensity}
              setDots={setDotsFromCar}
              setTyreDepth={setTyreDepthFromCar}
              setTyreNodes={setTyreNodesFromCar}
              setHasDrumBrake={setHasDrumBrakeFromCar}
              wheelScale={wheelScale}
              wheelSize={wheelSize}
              noWheel={noWheel}
            />
          </group>
          <hemisphereLight intensity={0.5} />
          { bgFile ? <Background bgFile={bgFile} /> : <></> }
          {/* <Effects /> */}
          <OrbitControls
            ref={orbitRef}
            enablePan={false}
            enableZoom={true}
            minPolarAngle={1.1}
            maxPolarAngle={Math.PI / 2.1}
            minDistance={3.78}
            maxDistance={12}
          />
          {
            hdriFile && hdrJpgFile
              ? <>
                <EnvBuilder
                  height={groundHeight} // Height of the camera that was used to create the env map (Default: 15)
                  radius={groundRadius} // Radius of the world. (Default 60)
                  scale={groundScale} // Scale of the backside projected sphere that holds the env texture (Default: 1000)
                  hdrJpg={hdrJpgFile}
                />
                <Environment
                  background={false}
                  files={hdriFile}
                />
              </>
              : hdriFile
                ? showGround
                  ? <Environment
                    ground={{
                      height: groundHeight, // Height of the camera that was used to create the env map (Default: 15)
                      radius: groundRadius, // Radius of the world. (Default 60)
                      scale: groundScale, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
                    }}
                    files={hdriFile}
                  />
                  : <Environment
                    background={false}
                    files={hdriFile}
                  />
                : showGround
                  ? <Environment
                    ground={{
                      height: groundHeight, // Height of the camera that was used to create the env map (Default: 15)
                      radius: groundRadius, // Radius of the world. (Default 60)
                      scale: groundScale, // Scale of the backside projected sphere that holds the env texture (Default: 1000)
                    }}
                    files={HDRI_LIST['canary_wharf']}
                  />
                  : <Environment
                    background={false}
                    files={HDRI_LIST['canary_wharf']}
                  />
          }
        </Canvas>
      </Suspense>
      <Leva />
    </div>
    : <></>
  );
}

export default ConfiguratorSanbox;
