import { useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { GroundProjectedEnv } from "three/examples/jsm/objects/GroundProjectedEnv";

export function EnvBuilder({
  hdrJpg,
  height,
  radius,
  scale
}) {
  const three = useThree();
  const texture = useLoader(THREE.TextureLoader, hdrJpg);
  texture.encoding = THREE.sRGBEncoding;
  texture.generateMipmaps = false;
  const env = new GroundProjectedEnv(texture);
  env.scale.setScalar(scale);
  env.height = height
  env.radius = radius
  three.scene.add(env);
  return <></>;
}