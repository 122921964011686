import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import App2 from './App2';
import CarOem from './CarOem';
import CarNoBrake from './CarNoBrake';
import ModelHdr from './ModelHdr';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ModelHdr />} />
        <Route path="/car" element={<App />} />
        <Route path="/wheel" element={<App2 />} />
        <Route path="/car-oem" element={<CarOem />} />
        <Route path="/car-no-brake" element={<CarNoBrake />} />
        <Route path="/*" element={ <Navigate to="/" /> } />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
