import { useState } from 'react';

import ConfiguratorSandbox from './components/ConfiguratorSandbox';
import WheelZoom from './components/WheelZoom';

function App(props) {
  const { isWheel } = props;
  const [carFile, setCarFile] = useState(null)
  const [carFileName, setCarFileName] = useState('')
  const [wheelFile, setWheelFile] = useState(null)
  const [wheelFileName, setWheelFileName] = useState('')
  const [bgFile, setBgFile] = useState(null)
  const [bgFileName, setBgFileName] = useState('')
  const [hdriFile, setHdriFile] = useState(null)
  const [hdriFileName, setHdriFileName] = useState('')
  const [hdriJpgFile, setHdriJpgFile] = useState(null)
  const [hdriJpgFileName, setHdriJpgFileName] = useState('')
  const [preview, setPreview] = useState(false);

  function onFileCarChange(e) {
    const file = e.target.files[0];
    setCarFileName(file.name);
    setCarFile(URL.createObjectURL(file))
  }

  function onFileWheelChange(e) {
    const file = e.target.files[0];
    setWheelFileName(file.name)
    setWheelFile(URL.createObjectURL(file))
  }

  function onFileHdriChange(e) {
    const file = e.target.files[0];
    setHdriFileName(file.name)
    setHdriFile(URL.createObjectURL(file))
  }

  function onFileHdriJpgChange(e) {
    const file = e.target.files[0];
    setHdriJpgFileName(file.name)
    setHdriJpgFile(URL.createObjectURL(file))
  }

  function onFileBgChange(e) {
    const file = e.target.files[0];
    setBgFileName(file.name)
    setBgFile(URL.createObjectURL(file))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '25px',
        fontSize: '20px'
      }}
      className="App"
    >
      {
        preview
          ? isWheel
            ? <WheelZoom wheelFile={wheelFile} />
            : <ConfiguratorSandbox
              wheelFile={wheelFile}
              carFile={carFile}
              bgFile={bgFile}
              hdriFile={hdriFile}
              hdrJpgFile={hdriJpgFile}
            />
          : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '16px',
              fontSize: '20px'
            }}
          >
            {
              isWheel
                ? <>
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileWheelChange} />
                    <i className="fa fa-cloud-upload" /> Wheel
                  </label>
                  <p>{ wheelFileName  }</p>
                  <hr />
                  <button
                    className={
                      'submit-btn' + (!wheelFile ? ' disabled' : '')
                    }
                    onClick={() => setPreview(true)}
                  >
                    Preview
                  </button>
                </>
                : <>
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileCarChange} />
                    <i className="fa fa-cloud-upload" /> Car
                  </label>
                  <p>{ carFileName }</p>
                  <hr />
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileWheelChange} />
                    <i className="fa fa-cloud-upload" /> Wheel
                  </label>
                  <p>{ wheelFileName  }</p>
                  <hr />
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileHdriChange} />
                    <i className="fa fa-cloud-upload" /> HDRI
                  </label>
                  <p>{ hdriFileName }</p>
                  <hr />
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileHdriJpgChange} />
                    <i className="fa fa-cloud-upload" /> HDR jpg
                  </label>
                  <p>{ hdriJpgFileName }</p>

                  <hr />
                  <label className="custom-file-upload">
                    <input type="file" onChange={onFileBgChange} />
                    <i className="fa fa-cloud-upload" /> BG
                  </label>
                  <p>{ bgFileName }</p>
                  <hr />
                  <button
                    className={
                      'submit-btn' + (!wheelFile || !carFile ? ' disabled' : '')
                    }
                    onClick={() => setPreview(true)}
                  >
                    Preview
                  </button>
                </>
            }
          </div>
      }
    </div>
  );
}

export default App;
