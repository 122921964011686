import { useState } from 'react';

import ConfiguratorSandbox from './components/ConfiguratorSandbox';

function CarOem() {
  const [carFile, setCarFile] = useState(null)
  const [carFileName, setCarFileName] = useState('')
  const [bgFile, setBgFile] = useState(null)
  const [bgFileName, setBgFileName] = useState('')
  const [hdriFile, setHdriFile] = useState(null)
  const [hdriFileName, setHdriFileName] = useState('')
  const [preview, setPreview] = useState(false);

  function onFileCarChange(e) {
    const file = e.target.files[0];
    setCarFileName(file.name);
    setCarFile(URL.createObjectURL(file))
  }

  function onFileHdriChange(e) {
    const file = e.target.files[0];
    setHdriFileName(file.name)
    setHdriFile(URL.createObjectURL(file))
  }

  function onFileBgChange(e) {
    const file = e.target.files[0];
    setBgFileName(file.name)
    setBgFile(URL.createObjectURL(file))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '25px',
        fontSize: '20px'
      }}
      className="App"
    >
      {
        preview
          ? <ConfiguratorSandbox
              carFile={carFile}
              bgFile={bgFile}
              hdriFile={hdriFile}
              noWheel={true}
            />
          : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '16px',
              fontSize: '20px'
            }}
          >
            <label className="custom-file-upload">
              <input type="file" onChange={onFileCarChange} />
              <i className="fa fa-cloud-upload" /> Car
            </label>
            <p>{ carFileName }</p>
            <hr />
            <label className="custom-file-upload">
              <input type="file" onChange={onFileHdriChange} />
              <i className="fa fa-cloud-upload" /> HDRI
            </label>
            <p>{ hdriFileName }</p>
            <hr />
            <label className="custom-file-upload">
              <input type="file" onChange={onFileBgChange} />
              <i className="fa fa-cloud-upload" /> BG
            </label>
            <p>{ bgFileName }</p>
            <hr />
            <button
              className={
                'submit-btn' + (!carFile ? ' disabled' : '')
              }
              onClick={() => setPreview(true)}
            >
              Preview
            </button>
          </div>
      }
    </div>
  );
}

export default CarOem;
