import { Suspense, useState, useMemo, useEffect, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import {
  OrbitControls,
  PerspectiveCamera,
  Environment,
  useProgress,
  useGLTF,
} from '@react-three/drei';

import jflogo from '../assets/jflogo.png';
import Progress from '../components/Progress';
import Effects from '../components/Effects';

const HDRI_LIST = {
  'canary_wharf': require('../assets/hdri/canary_wharf_2k_fixed-red.hdr'),
};

function WheelZoom({ wheelFile }) {
  const camRef = useRef();
  const lightRef = useRef();
  const { scene: wheelScene } = useGLTF(wheelFile, '/draco/gltf/')

  const [showDelayLoading, setShowDelayLoading] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [selectHdri, setSelectHdri] = useState(HDRI_LIST['canary_wharf']);

  const { progress, loaded } = useProgress();

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  function Loader() {
    return <Progress strokeWidth={8} percentage={progress} />;
  }
  function LoadingScreen() {
    return (
      <div className="loading-overlay screen show">
        <Loader />
      </div>
    );
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setShowDelayLoading(true)
      setShowDelayLoadingWithDelay({
        status: false,
        delay: 5000
      });
    }
  }, [progress, loaded])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function setShowDelayLoadingWithDelay({ status, delay }) {
    if (!delay) {
      setShowDelayLoading(status)
      return;
    }
    setTimeout(() => {
      setShowDelayLoading(status)
    }, delay)
  }

  return (
    wheelFile ? <div className="Configurator">
      <div className={'loading-overlay' + (showDelayLoading ? ' show' : '')}>
        <img src={jflogo} alt="jflogo" />
      </div>
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.7,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          id="three-canvas"
        >
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 40 : 20}
            makeDefault
            position={[-0.07279424141991943, 0.07593999195817443, 2.198117655517967]}
          />
          <pointLight
            ref={lightRef}
            position={[-0.63, 0.44, 2.85]}
            distance={63.81}
            intensity={0.29}
          />
          <primitive object={wheelScene} />
          <hemisphereLight intensity={0.5} />
          <Effects />
          <OrbitControls
            enablePan={true}
            enableZoom={true}
            minPolarAngle={0}
            maxPolarAngle={Math.PI}
            minDistance={1}
            maxDistance={3}
            // onChange={onCameraChange}
          />
          <Environment
            background={false} // Whether to affect scene.background
            files={selectHdri}
          />
        </Canvas>
      </Suspense>
    </div>
    : <></>
  );
}

export default WheelZoom;
