import { applyProps } from '@react-three/fiber';
import { useState, useRef, useEffect, useMemo } from 'react'

import * as THREE from "three";

function Wheel(props) {
  const {
    scene,
    side,
    dot,
    wheelScale,
    setWheelSize,
    tyreDepth,
    tyreWheelCircleNodes,
    brakeScene,
    noBrake,
  } = props;

  const wheelRef = useRef()
  const light = useRef();
  const [wheelFacePoint, setWheelFacePoint] = useState(0);
  const [wheelFaceAndEdgeDistance, setWheelFaceAndEdgeDistance] = useState(0);
  const [wheelSizeAfterGetFacePoint, setWheelSizeAfterGetFacePoint] = useState(0);
  const [dotTarget, setDotTarget] = useState(new THREE.Vector3(0));

  useEffect(() => {
    const target = new THREE.Vector3(0)
    dot.getWorldPosition(target)
    setDotTarget(target);
  }, [dot])

  const wheelEdge = useMemo(() => {
    const mesh = scene.children.find(item => item.type === 'Mesh' && item.name.includes('wheel_edge'));
    return mesh;
  }, [scene])

  function setWheelSizeToCar() {
    if (!setWheelSize) {
      return;
    }
    const bb = new THREE.Box3().setFromObject(scene);
    const wheelSizeTarget = new THREE.Vector3();
    bb.getSize(wheelSizeTarget)
    setWheelSize(wheelSizeTarget);
  }

  useEffect(() => {
    setWheelSizeToCar();
  }, [wheelScale])

  useEffect(() => {
    applyProps(wheelEdge, { visible: false })
    /**
     * Raycaster
     */
    const raycaster = new THREE.Raycaster()
    const rayOrigin = dotTarget;
    const rayZdir = side === 'right' ? -5 : 5;

    const rayDirection = new THREE.Vector3(0, 0, rayZdir)
    rayDirection.normalize()
    raycaster.set(rayOrigin, rayDirection)
    setTimeout(() => {
      const intersects = raycaster.intersectObjects([tyreWheelCircleNodes])
      const foundedWheelSizeFace = intersects.find((item) => item.object.type === 'Mesh' && item.object.name.includes('wheelsize_circle'));
      if (foundedWheelSizeFace) {
        setWheelFacePoint(foundedWheelSizeFace.distance);
      }
    }, 500)

    if (wheelFacePoint === 0) {
      return;
    }
    const bb = new THREE.Box3().setFromObject(scene);
    const wheelSizeTarget = new THREE.Vector3();
    bb.getSize(wheelSizeTarget)
    setWheelSizeAfterGetFacePoint(wheelSizeTarget.z)
    setTimeout(() => {
      const wheelEdgeTarget = new THREE.Vector3()
      wheelEdge.getWorldPosition(wheelEdgeTarget)
      const raycaster = new THREE.Raycaster()
      const rayOrigin = dotTarget;
      const rayZdir = side === 'right' ? -5 : 5;
      const rayDirection = new THREE.Vector3(0, 0, rayZdir)
      rayDirection.normalize()
      raycaster.set(rayOrigin, rayDirection)
      const intersects = raycaster.intersectObjects([wheelEdge])
      const foundedWheelEdge = intersects.find((item) => item.object.type === 'Mesh' && item.object.name.includes('wheel_edge'));
      if (foundedWheelEdge) {
        setWheelFaceAndEdgeDistance(foundedWheelEdge.distance - wheelFacePoint);
      }
    }, 500)
  }, [scene, dotTarget, side, wheelEdge, tyreWheelCircleNodes, wheelFacePoint])

  if (side === 'right')
    return (
      <group
        scale={[1, 1, 1 - tyreDepth]}
        position={[dotTarget.x, dotTarget.y + 0.4, dotTarget.z - wheelFacePoint - wheelFaceAndEdgeDistance + (wheelSizeAfterGetFacePoint / 2)]}
      >
        { noBrake ? <primitive object={brakeScene} rotation={[Math.PI, 0, 0]} scale={[wheelScale * 1.1, wheelScale * 1.1, 1]} /> : <></>}
        <primitive
          ref={wheelRef}
          object={scene}
          rotation={[0, Math.PI, 0]}
          scale={[wheelScale, wheelScale, 1]}
        />
        <pointLight ref={light} position={[0, 0.2, -0.4]} intensity={1} distance={2} decay={1} />
      </group>
    );
  return (
    <group
      scale={[1, 1, 1 - tyreDepth]}
      position={[dotTarget.x, dotTarget.y + 0.4, dotTarget.z + wheelFacePoint + wheelFaceAndEdgeDistance - (wheelSizeAfterGetFacePoint / 2)]}
    >
      { noBrake ? <primitive object={brakeScene} scale={[wheelScale * 1.1, wheelScale * 1.1, 1]} /> : <></> }
      <primitive
        ref={wheelRef}
        object={scene}
        scale={[wheelScale, wheelScale, 1]}
      />
      <pointLight ref={light} position={[0, 0.2, 0.4]} intensity={1} distance={2} decay={1} />
    </group>
  );
}

export default Wheel;

