import { Suspense, useState } from 'react';

import WheelZoom from './components/WheelZoom';

function App2() {
  const [wheelFile, setWheelFile] = useState(null)
  const [wheelFileName, setWheelFileName] = useState('')
  const [preview, setPreview] = useState(false);

  function onFileWheelChange(e) {
    const file = e.target.files[0];
    setWheelFileName(file.name)
    setWheelFile(URL.createObjectURL(file))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '25px',
        fontSize: '20px'
      }}
      className="App"
    >
      <Suspense fallback={null}>
      {
        preview
          ? <WheelZoom wheelFile={wheelFile} />
          : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '16px',
              fontSize: '20px'
            }}
          >
            <label className="custom-file-upload">
              <input type="file" onChange={onFileWheelChange} />
              <i className="fa fa-cloud-upload" /> Wheel
            </label>
            <p>{ wheelFileName  }</p>
            <hr />
            <button
              className={
                'submit-btn' + (!wheelFile ? ' disabled' : '')
              }
              onClick={() => setPreview(true)}
            >
              Preview
            </button>
          </div>
      }
      </Suspense>
    </div>
  );
}

export default App2;
