import { useState, useEffect, useMemo } from 'react'
import { useGLTF, useHelper } from '@react-three/drei';
import { applyProps } from '@react-three/fiber'
import * as THREE from "three";
import { gsap } from "gsap";

function Car(props) {
  const {
    carId,
    modelFile,
    suspension,
    color,
    setDots,
    setTyreDepth,
    setTyreNodes,
    setHasDrumBrake,
    wheelSize,
    wheelScale,
    noWheel
  } = props;
  const { scene, nodes, materials } = useGLTF(modelFile, '/draco/gltf/')

  const initBodyNodeY = sessionStorage.getItem('carypos')
    ? JSON.parse(sessionStorage.getItem('carypos'))
    : {};

  const [bodyNodeY, setBodyNodeY] = useState({});
  const [tyreSize, setTyreSize] = useState(new THREE.Vector3());
  const [wheelCircleSize, setWheelCircleSize] = useState(new THREE.Vector3());

  const bodyNode = useMemo(() => Object.values(nodes).find(item => item.name.includes('_body')), [nodes])

  useEffect(() => {
    if (noWheel) {
      bodyNode.children.forEach((ch) => {
        if (ch.isMesh) {
          applyProps(ch, { castShadow: true });
        }
      })

      applyProps(nodes['tyre_va_l'], { receiveShadow: true });
      applyProps(nodes['tyre_va_l'], { castShadow: true });
      applyProps(nodes['tyre_va_r'], { receiveShadow: true });
      applyProps(nodes['tyre_va_r'], { castShadow: true });
      applyProps(nodes['tyre_ha_l'], { receiveShadow: true });
      applyProps(nodes['tyre_ha_l'], { castShadow: true });
      applyProps(nodes['tyre_ha_r'], { receiveShadow: true });
      applyProps(nodes['tyre_ha_r'], { castShadow: true });

      Object.keys(materials).forEach((matkey) => {
        if (matkey.includes('Mat_tyres-uv')) {
          applyProps(materials[matkey], { envMapIntensity: 0.55, emissiveIntensity: 0.8 })
        }
        if (matkey.includes('Mat_bottom')) {
          applyProps(materials[matkey], { envMapIntensity: 0, roughness: 1 })
        }
      })
      if (materials['Mat_paint black_shield']) {
        applyProps(materials['Mat_paint black_shield'], { envMapIntensity: 0.1, opacity: 0.98, emissiveIntensity: 0, color: '#0f0f0f', transparent: true })
      }

      applyProps(materials["Mat_front-glass"], { ior: 1.1, iridescenceIOR: 1.2, specularIntensity: 1.8, clearcoat: 0.35 })
      if (materials['Mat_rear-lights']) {
        applyProps(materials['Mat_rear-lights'], { emissiveIntensity: 0.25, color:  new THREE.Color('#FF1A05').convertLinearToSRGB() })
      }
      applyProps(materials['Mat_car_glossy'], { color:  new THREE.Color(color).convertLinearToSRGB() })

      if (!initBodyNodeY[carId]) {
        sessionStorage.setItem('carypos', JSON.stringify({
          ...initBodyNodeY,
          [carId]: bodyNode.clone().position.y
        }));
        setBodyNodeY({
          ...bodyNodeY,
          [carId]: bodyNode.clone().position.y
        });
      } else {
        applyProps(bodyNode.position, { y: bodyNodeY[carId] });
      }
      return;
    }
    const bbWheel =  new THREE.Box3().setFromObject(nodes['wheelsize_circle_va_l']);
    const wheelCircleSizeVector = new THREE.Vector3();
    bbWheel.getSize(wheelCircleSizeVector)
    setWheelCircleSize(wheelCircleSizeVector)
    const bb = new THREE.Box3().setFromObject(nodes['tyres_va_l']);
    const tyreSizeVector = new THREE.Vector3();
    bb.getSize(tyreSizeVector)
    // x and y are equal because it's square box.
    setTyreSize(tyreSizeVector)
    // z is box depth.
    setTyreDepth(tyreSizeVector.z);
    setTyreNodes([
      nodes['tyres_va_l'],
      nodes['tyres_va_r'],
      nodes['tyres_ha_l'],
      nodes['tyres_ha_r'],
      nodes['wheelsize_circle_va_l'],
      nodes['wheelsize_circle_va_r'],
      nodes['wheelsize_circle_ha_l'],
      nodes['wheelsize_circle_ha_r'],
    ])
    setDots([
      nodes.dots_va_l,
      nodes.dots_va_r,
      nodes.dots_ha_l,
      nodes.dots_ha_r,
    ])
    applyProps(nodes['_dots'], { visible: false });

    bodyNode.children.forEach((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
      }
    })
    nodes['tyres_va_l'].children.forEach((obj) => {
      if (obj.isMesh) {
        applyProps(obj, { receiveShadow: true });
        applyProps(obj, { castShadow: true });
      }
    })
    nodes['tyres_va_r'].children.forEach((obj) => {
      if (obj.isMesh) {
        applyProps(obj, { receiveShadow: true });
        applyProps(obj, { castShadow: true });
      }
    })
    nodes['tyres_ha_l'].children.forEach((obj) => {
      if (obj.isMesh) {
        applyProps(obj, { receiveShadow: true });
        applyProps(obj, { castShadow: true });
      }
    })
    nodes['tyres_ha_r'].children.forEach((obj) => {
      if (obj.isMesh) {
        applyProps(obj, { receiveShadow: true });
        applyProps(obj, { castShadow: true });
      }
    })

    applyProps(nodes['tyres_va_l'], { receiveShadow: true });
    applyProps(nodes['tyres_va_l'], { castShadow: true });
    applyProps(nodes['tyres_va_r'], { receiveShadow: true });
    applyProps(nodes['tyres_va_r'], { castShadow: true });
    applyProps(nodes['tyres_ha_l'], { receiveShadow: true });
    applyProps(nodes['tyres_ha_l'], { castShadow: true });
    applyProps(nodes['tyres_ha_r'], { receiveShadow: true });
    applyProps(nodes['tyres_ha_r'], { castShadow: true });

    Object.keys(materials).forEach((matkey) => {
      if (matkey.includes('Mat_tyres')) {
        applyProps(materials[matkey], { envMapIntensity: 0.55, emissiveIntensity: 0.8 })
      }
      if (matkey.includes('Mat_bottom')) {
        applyProps(materials[matkey], { envMapIntensity: 0, roughness: 1 })
      }
    })
    if (materials['Mat_paint black_shield']) {
      applyProps(materials['Mat_paint black_shield'], { envMapIntensity: 0.1, opacity: 0.98, emissiveIntensity: 0, color: '#0f0f0f', transparent: true })
    }

    // applyProps(materials["Mat_tyres-uv"], { envMapIntensity: 0.1, roughness: 1 })
    // applyProps(materials['Mat_tyres-markfillhole'], { envMapIntensity: 0.7, clearcoatRoughness: 0.8, roughness: 0.8 })

    if (nodes['_drum_brakes']) {
      setHasDrumBrake(true);
    }

    // Mark center of texture
    applyProps(materials['Mat_tyres-markfillhole'].map, {
      center: new THREE.Vector2(0.5, 0.5),
    })

    applyProps(materials["Mat_front-glass"], { ior: 1.1, iridescenceIOR: 1.2, specularIntensity: 1.8, clearcoat: 0.35 })
    if (materials['Mat_rear-lights']) {
      applyProps(materials['Mat_rear-lights'], { emissiveIntensity: 0.25, color:  new THREE.Color('#FF1A05').convertLinearToSRGB() })
    }
    applyProps(materials['Mat_car_glossy'], { color:  new THREE.Color(color).convertLinearToSRGB() })
    if (!initBodyNodeY[carId]) {
      sessionStorage.setItem('carypos', JSON.stringify({
        ...initBodyNodeY,
        [carId]: bodyNode.clone().position.y
      }));

      setBodyNodeY({
        ...bodyNodeY,
        [carId]: bodyNode.clone().position.y
      });
    } else {
      applyProps(bodyNode.position, { y: bodyNodeY[carId] });
    }
  }, [scene, materials, nodes, color, carId]);

  useEffect(() => {
    if (initBodyNodeY[carId]) {
      gsap.to(bodyNode.position, { y: initBodyNodeY[carId] + suspension });
      setBodyNodeY({
        ...bodyNodeY,
        [carId]: initBodyNodeY[carId] + suspension
      });
    }
  }, [suspension]);

  useEffect(() => {
    if (wheelSize.x === 0 || tyreSize.x === 0 || wheelCircleSize.x === 0) {
      return;
    }
    applyProps(materials['Mat_tyres-markfillhole'].map, {
      repeat: new THREE.Vector2(1 / wheelScale, 1 / wheelScale),
    })
  }, [scene, materials, wheelSize, tyreSize, wheelCircleSize, wheelScale]);

  return (
    <group>
      <primitive
        object={scene}
      />
    </group>
  );
}

export default Car;
