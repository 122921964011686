import Wheel from './Wheel';
import { useEffect, useMemo, useState } from 'react'
import { applyProps } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import brakeModel from '../assets/brake/brakes_basesize.glb';

function WheelAndDots(props) {
  const {
    wheelFile,
    dots,
    tyreDepth,
    tyreNodes,
    wheelScale,
    selectedCarIndex,
    selectedWheelIndex,
    setWheelSize,
    noBrake,
    hasDrumBrake,
    brakeFile
  } = props;

  const brakeModelFile = brakeFile ? brakeFile : brakeModel;
  const { scene: wheelScene } = useGLTF(wheelFile, '/draco/gltf/')
  const { scene: brakeScene } = useGLTF(brakeModelFile, '/draco/gltf/')

  const wheelFrontLeftScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelFrontRightScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelRearLeftScene = useMemo(() => wheelScene.clone(), [wheelScene])
  const wheelRearRightScene = useMemo(() => wheelScene.clone(), [wheelScene])

  const brakeFrontLeftScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeFrontRightScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeRearLeftScene = useMemo(() => brakeScene.clone(), [brakeScene])
  const brakeRearRightScene = useMemo(() => brakeScene.clone(), [brakeScene])

  useEffect(() => {
    wheelFrontLeftScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelFrontLeftScene])

  useEffect(() => {
    wheelFrontRightScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelFrontRightScene])

  useEffect(() => {
    wheelRearLeftScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelRearLeftScene])

  useEffect(() => {
    wheelRearRightScene.traverse(function(obj) {
      if (obj.children && obj.children.length && obj.children[0].type === "PointLight") {
        applyProps(obj, { visible: false })
      }
    })
  }, [wheelRearRightScene])

  function setWheelSizeFrontLeft(event) {
    setWheelSize(event)
  }

  if (dots.length === 0) {
    return <></>
  }

  return (
    <group>
      <Wheel
        key={selectedWheelIndex + 'frontLeft' + selectedCarIndex}
        scene={wheelFrontLeftScene}
        brakeScene={brakeFrontLeftScene}
        noBrake={noBrake}
        side="left"
        dot={dots[0]}
        tyreNodes={tyreNodes[0]}
        tyreWheelCircleNodes={tyreNodes[4]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
        setWheelSize={setWheelSizeFrontLeft}
      />
      <Wheel
        key={selectedWheelIndex + 'frontRight' + selectedCarIndex}
        scene={wheelFrontRightScene}
        brakeScene={brakeFrontRightScene}
        noBrake={noBrake}
        side="right"
        dot={dots[1]}
        tyreNodes={tyreNodes[1]}
        tyreWheelCircleNodes={tyreNodes[5]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
      />
      <Wheel
        key={selectedWheelIndex + 'rearLeft' + selectedCarIndex}
        scene={wheelRearLeftScene}
        brakeScene={brakeRearLeftScene}
        noBrake={noBrake && !hasDrumBrake}
        side="left"
        dot={dots[2]}
        tyreNodes={tyreNodes[2]}
        tyreWheelCircleNodes={tyreNodes[6]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
      />
      <Wheel
        key={selectedWheelIndex + 'rearRight' + selectedCarIndex}
        scene={wheelRearRightScene}
        brakeScene={brakeRearRightScene}
        noBrake={noBrake && !hasDrumBrake}
        side="right"
        dot={dots[3]}
        tyreNodes={tyreNodes[3]}
        tyreWheelCircleNodes={tyreNodes[7]}
        tyreDepth={tyreDepth}
        wheelScale={wheelScale}
      />
    </group>
  )
}

export default WheelAndDots;
