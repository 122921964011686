import { EffectComposer, Bloom } from '@react-three/postprocessing'

function Effects() {
  return (
    <EffectComposer>
      <Bloom
        luminanceThreshold={0.5} // luminance threshold. Raise this value to mask out darker elements in the scene.
        luminanceSmoothing={0.55}
        mipmapBlur
        intensity={1.5}
        radius={0.2}
      />
    </EffectComposer>
  )
}

export default Effects;