import { useGLTF } from '@react-three/drei';
import { applyProps } from '@react-three/fiber'
import { useEffect } from 'react';
import { Vector3 } from 'three';

function CarPart(props) {
  const {
    modelFile,
  } = props;
  const { scene } = useGLTF(modelFile, '/draco/gltf/')

  useEffect(() => {
    scene.traverse((ch) => {
      if (ch.isMesh) {
        applyProps(ch, { castShadow: true });
        applyProps(ch, { position: new Vector3(0,0,0) });
      }
    })
  }, [scene])

  return (
    <group position={[0,0.05,0]} scale={[0.8, 0.8, 0.8]}>
      <primitive object={scene} />
    </group>
  );
}

export default CarPart;
