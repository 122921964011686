import { useRef, useMemo, useEffect, useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber'
import {
  CameraControls,
  PerspectiveCamera,
  Environment,
  useProgress,
} from '@react-three/drei';
import * as THREE from "three";
import { Triangle } from 'react-loader-spinner'
import { debounce } from 'lodash';
import CarPart from './components/CarPart';

import './index.css';

function LoadingScreen(props) {
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    if (props.loaded) {
      setTimeout(() => {
        setIsHidden(true);
      }, 1000);
    }
  }, [props.loaded])
  return <div
    className={
      "w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 filter backdrop-blur transition-opacity delay-500 duration-500 z-40"
      + (props.loaded ? ' opacity-0' : ' opacity-100')
      + (isHidden ? ' invisible' : ' ')
    }
  >
    <Triangle
      height="80"
      width="80"
      color="#000"
      ariaLabel="triangle-loading"
      wrapperStyle={{}}
      wrapperClass="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      visible={true}
    />
  </div>;
}

function ModelHdr() {
  const camRef = useRef();
  const cameraControlsRef = useRef();

  const startDistance = useRef(0);
  const distanceCount = useRef(0);
  const endDistance = useRef(0);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const isMobileAndTabletControlVertical = useMemo(() => windowSize.innerWidth < 800 && window.matchMedia('(orientation: portrait)').matches, [windowSize.innerWidth]);

  const [showContact, setShowContact] = useState(false);

  const [showLogo, setShowLogo] = useState(false);

  const [selectedModel, setSelectedModel] = useState(1);

  const { progress, loaded } = useProgress();

  const [preview, setPreview] = useState(false);

  const [modelFile, setModelFile] = useState(null)
  const modelFileRef = useRef(null)
  const [modelFileName, setModelFileName] = useState('')

  function onFileModelChange(e) {
    const file = e.target.files[0];
    setModelFileName(file.name)
    setModelFile(URL.createObjectURL(file))
    modelFileRef.current = URL.createObjectURL(file);
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (progress === 100 && loaded > 0) {
      setTimeout(() => {
        setShowLogo(true)
      }, 2000);
    } else {
      setShowLogo(false)
    }
  }, [progress, loaded])

  useEffect(() => {
    if (!cameraControlsRef.current) {
      return;
    }
    setTimeout(() => {
      if (selectedModel === 2) {
        cameraControlsRef.current.setPosition(-1.0774443844849133, 1.3406724999847257, 2.3296302930505046, true);
      } else {
        cameraControlsRef.current.setPosition(-1.4780651446558473, 0.8397318951435856, 2.171515730546785, true);
      }
      cameraControlsRef.current.saveState();
      cameraControlsRef.current.setTarget(0, 0, 0, true);
    }, 200);
  }, [selectedModel])

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function onCameraUpdate(e) {
    if (cameraControlsRef.current) {
      if (distanceCount.current > 0.9) {
        startDistance.current = e.target.distance;
        distanceCount.current = 0;
      } else {
        distanceCount.current += 0.01;
      }
      handleOnZoom(e.target.distance);
    }
  }

  const handleOnZoom = (distance) => {
    if (distance > startDistance.current) {
      endDistance.current = startDistance.current;
    }
    if (endDistance.current !== 0 && (distance > endDistance.current && distance - endDistance.current > 0.5)) {
      setZoomOut();
    }
  };

  const setZoomOut = debounce(function() {
    endDistance.current = 0;
    cameraControlsRef.current.dolly(-5, true);
    cameraControlsRef.current.setTarget(0, 0, 0, true);
  }, 500);

  return !preview
    ? <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '25px',
        fontSize: '20px',
        height: 'var(--vh)'
      }}
      className="App"
    >
      <label className="custom-file-upload">
        <input type="file" onChange={onFileModelChange} />
        <i className="fa fa-cloud-upload" /> Model
      </label>
      <p>{ modelFileName  }</p>
      <hr />
      <button
        className={
          'submit-btn' + (!modelFile ? ' disabled' : '')
        }
        onClick={() => setPreview(true)}
      >
        Preview
      </button>
    </div>
    : <div
      className="relative"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <Canvas
          gl={{
            antialias: true,
            toneMappingExposure: 0.6,
            pixelRatio: Math.min(window.devicePixelRatio, 2),
          }}
          shadows={THREE.VSMShadowMap}
          id="three-canvas"
          className="w-full h-full"
        >
          <CarPart
            modelFile={modelFileRef.current}
          />
          <hemisphereLight intensity={0.2} />
          <PerspectiveCamera
            ref={camRef}
            fov={isMobileAndTabletControlVertical ? 45 : 30}
            position={selectedModel === 2 ? [-1.0774443844849133, 1.3406724999847257, 2.3296302930505046] : [-1.4780651446558473, 0.8397318951435856, 2.171515730546785]}
            makeDefault
          />
          <Environment
              background={false} // Whether to affect scene.background
              files={require('./assets/hdri/canary_wharf_2k_fixed-red.hdr')}
            />
          <CameraControls
            ref={cameraControlsRef}
            minDistance={isMobileAndTabletControlVertical ? 1 : 1.1}
            maxDistance={isMobileAndTabletControlVertical ? 2.2 : 2.7}
            dollyToCursor={true}
            dollySpeed={0.5}
            smoothTime={0.09}
            draggingSmoothTime ={0.15}
            truckSpeed={0}
            onChange={onCameraUpdate}
          />
        </Canvas>
      </Suspense>
    </div>;
}

export default ModelHdr;
