import { useState } from 'react';

import ConfiguratorSandbox from './components/ConfiguratorSandbox';

function CarNoBrake() {
  const [carFile, setCarFile] = useState(null)
  const [carFileName, setCarFileName] = useState('')
  const [wheelFile, setWheelFile] = useState(null)
  const [wheelFileName, setWheelFileName] = useState('')
  const [brakeFile, setBrakeFile] = useState(null)
  const [brakeFileName, setBrakeFileName] = useState('')
  const [preview, setPreview] = useState(false);

  function onFileCarChange(e) {
    const file = e.target.files[0];
    setCarFileName(file.name);
    setCarFile(URL.createObjectURL(file))
  }

  function onFileWheelChange(e) {
    const file = e.target.files[0];
    setWheelFileName(file.name);
    setWheelFile(URL.createObjectURL(file))
  }

  function onFileBrakeChange(e) {
    const file = e.target.files[0];
    setBrakeFileName(file.name);
    setBrakeFile(URL.createObjectURL(file))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '25px',
        fontSize: '20px'
      }}
      className="App"
    >
      {
        preview
          ? <ConfiguratorSandbox
              carFile={carFile}
              wheelFile={wheelFile}
              brakeFile={brakeFile}
              noBrake={true}
            />
          : <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              rowGap: '16px',
              fontSize: '20px'
            }}
          >
            <label className="custom-file-upload">
              <input type="file" onChange={onFileCarChange} />
              <i className="fa fa-cloud-upload" /> Car
            </label>
            <p>{ carFileName }</p>
            <hr />
            <label className="custom-file-upload">
              <input type="file" onChange={onFileWheelChange} />
              <i className="fa fa-cloud-upload" /> Wheel
            </label>
            <p>{ wheelFileName }</p>
            <hr />
            <label className="custom-file-upload">
              <input type="file" onChange={onFileBrakeChange} />
              <i className="fa fa-cloud-upload" /> Brake
            </label>
            <p>{ brakeFileName }</p>
            <hr />
            <button
              className={
                'submit-btn' + (!carFile ? ' disabled' : '')
              }
              onClick={() => setPreview(true)}
            >
              Preview
            </button>
          </div>
      }
    </div>
  );
}

export default CarNoBrake;
